import React, { useState, useEffect } from 'react';
import get from 'lodash/fp/get';
import compact from "lodash/fp/compact";

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import Button from '@material-ui/core/Button';
import Link from "next/link";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import includes from 'lodash/includes';
import { Auth } from "aws-amplify";

import { CURRENT_ENV } from '../../../../utils/constants';
import { useUserGroups } from '../../../../utils/use-user';
import { useGetEmployeeForForm } from '../../../../utils/crm/use-employee';
import SalesMeetingForm from '../../../sales-meeting-form/SalesMeetingForm';
import NewReportDialog from "../../../sales-meeting-form/NewReportDialog";

import { useSnackbar } from 'notistack';
import { useDialog } from '../../../../providers/dialogs/DialogProvider';
import { useCreateContact } from '../../../../utils/crm/use-contact';
import { useCreateCompany } from '../../../../utils/crm/use-company';

// const disableProd = Boolean(CURRENT_ENV == 'production');
const disableProd = false;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    minHeight: 300
  },
  header: {
    height: 80,
  },
  action:{
    marginRight: 10
  }
}));

const LinkedItem = ({ page_id, type, label }) => (
  <Link
    href={{
      pathname: `/website/pages/${type.toLowerCase()}s/[page_id]/new-${type.toLowerCase()}`,
      query: { pageName: label, type: type }
    }}
    as={`/website/pages/${type.toLowerCase()}s/${page_id}/new-${type.toLowerCase()}`}
  >
    <MenuItem>{label}</MenuItem>
  </Link>
)


const QuickActions = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [dialog, setDialog] = useState(null);
  const userId = Auth.user.username;
  const [reportButton, setReportButton] = useState("Create Sales Report");
  const fetchEmployee = useGetEmployeeForForm(userId);
  const [initialValues, setInitialValues] = useState({
    notes: "",
    quotesAndLeads: "",
    scheduledPresentations: "",
    lastWeekValues: [],
    nextWeekValues: []
  });

  const groups = useUserGroups();
  const isManager = includes(groups, 'manager');
  const isAdmin = includes(groups, 'admin');
  const isEditor = includes(groups, 'editor');
  const isSales = includes(groups, 'sales');

  const [openDialog, closeDialog] = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const createContact = useCreateContact();
  const createCompany = useCreateCompany();

  const handleOpenDialog = (variant, option) => {
    switch(variant){
      case('contact'): {
        openDialog({
          variant: 'form',
          form: 'contact',
          id: 'create-contact',
          submitLabel: 'Create',
          title: 'Create Contact',
          maxWidth: 'lg',
          onSubmit: handleCreateContact,
          newContact: true
        });
        break;
      }
      case('company'): {
        openDialog({
          variant: 'form',
          form: 'company',
          id: 'create-company',
          submitLabel: 'Create',
          title: 'Create Company',
          maxWidth: 'lg',
          onSubmit: handleCreateCompany,
          newCompany: true
        });
        break;
      }
      default: {
        console.log('no action passed.');
        alert('no action passed.');
      }
    } 
  }

  const handleCreateContact = async (values) => {
    closeDialog();
    values.groups = compact([get('mailingGroups', values), get('regionGroups', values), get('industryGroups', values)]);
		try {
      enqueueSnackbar(`Creating Contact...`);
			await createContact(values);	
			enqueueSnackbar('Done!', { variant: 'success'})
		} catch (e) {
			console.log(e);
      enqueueSnackbar('Error creating contact...', {
        variant: 'error',
        persist: true,
        errors: e
      });
		}
	};

  const handleCreateCompany = async values => {
    closeDialog();
		try {
      enqueueSnackbar(`Creating Company...`);
			await createCompany(values);	
			enqueueSnackbar('Done!', { variant: 'success'})
		} catch (e) {
			console.log(e);
      enqueueSnackbar('Error creating company...', {
        variant: 'error',
        persist: true,
        errors: e
      });
		}
  }

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleReportClick = () => {
    setDialog('create-report');
  };

  useEffect(() => {
    async function fetchData() {
      const employee = await fetchEmployee(userId);
      const reportFormData = get('reportFormData', employee) || "{}"
      const formData = JSON.parse(reportFormData)
      const formFields = get('formFields', formData) || {}
      const notes = get('notes', formFields) || ""
      const scheduledPresentations = get('scheduledPresentations', formFields) || ""
      const quotesAndLeads = get('quotesAndLeads', formFields) || ""
      const lastWeekValues = get('lastWeekValues', formFields) || []
      const nextWeekValues = get('nextWeekValues', formFields) || []

      setInitialValues(formFields)
      if (notes == "" && scheduledPresentations == "" && quotesAndLeads == "" && lastWeekValues.length == 0 && nextWeekValues.length == 0) {
        setReportButton("Create Sales Report")
      } else {
        setReportButton("Continue Sales Report")
      }
    }
    fetchData()
  }, [dialog]);

  return (
    <Toolbar className={classes.header} disableGutters>
      <If condition={!disableProd && (isManager || isAdmin || isSales)}>
          <Button
            variant="contained"
            className={classes.action}
            color="primary"
            onClick={() => handleOpenDialog('contact')}
          >
            Create Contact
          </Button>
          <Button
            variant="contained"
            className={classes.action}
            color="primary"
            onClick={() => handleOpenDialog('company')}
          >
            Create Company
          </Button>
      </If>
      <If condition={isManager || isAdmin || isEditor}>
        <Button
          aria-describedby="create-product"
          variant="contained"
          className={classes.action}
          color="primary"
          onClick={e => handleClick(e, 'create-product')}
        >
          Create Product
        </Button>
        <Button
          aria-describedby="create-material"
          variant="contained"
          className={classes.action}
          color="primary"
          onClick={e => handleClick(e, 'create-material')}
        >
          Create Material
        </Button>
      </If>
        {/* <Button
          aria-describedby="create-report"
          variant="contained"
          className={classes.action}
          color="primary"
          onClick={handleReportClick}
        >
          {reportButton}
        </Button> */}
        <NewReportDialog
          id={"create-report"}
          Form={SalesMeetingForm}
          open={dialog === 'create-report'}
          onSubmit={() => {}}
          onCancel={() => setDialog(null)}
          newReportDialogInitialValues={initialValues}
          setInitialValues={setInitialValues}
          setReportButton={setReportButton}
        />
      <Popper 
        id="create-product"
        open={open === 'create-product'} 
        anchorEl={anchorEl} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
            <Paper style={{marginTop: 5}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <LinkedItem page_id="7uruqd0qREdvDGT6mvJSmM" type="Product" label="Indoor" />
                  <LinkedItem page_id="EE5Y8KNxQfKh9YkGaXHrb" type="Product" label="Outdoor" />
                  <LinkedItem page_id="tJ8akeWSeBSzkFKBArXzR" type="Product" label="Ready Made" />
                  <LinkedItem page_id="6exBqMJ7w2K0tRs0tj1Chk" type="Product" label="LA Display Sale" />
                  <LinkedItem page_id="7Hh2uknER2ny9schS7b18R" type="Product" label="SF Display Sale" />
                </MenuList>
              </ClickAwayListener>
            </Paper>
      </Popper>
      <Popper 
        id="create-material"
        open={open === 'create-material'} 
        anchorEl={anchorEl} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
            <Paper style={{marginTop: 5}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                  <LinkedItem page_id="7EfVcaWufa4v65NW1WDY7F" type="Material" label="Indoor" />
                  <LinkedItem page_id="4s9LbIiKUejeGeN6420fcb" type="Material" label="Outdoor" />
                </MenuList>
              </ClickAwayListener>
            </Paper>
      </Popper>
    </Toolbar>
  )
};

export default QuickActions;