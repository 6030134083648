import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as actions from '../../redux/actions/cms';
import useActions from '../use-actions';

export const useAsset = id => {
  if(!id) return;
  const asset = useSelector(state => state.cms.assets[id]);
  return asset;
};

// export const useAssets = () => {
//   const getAssets = useActions(actions.getAssets);

//   useEffect(() => {
//     getAssets();
//   });

//   return {
//     id: '0',
//     name: 'test'
//   }
// };

export const useAssets = () => {
  const getAssets = useActions(actions.getAssets);
  return async() => {
    console.log('getting assets');
    await getAssets();
  }
}

export const useDeleteAsset = () => {
  const deleteAsset = useActions(actions.deleteAsset);
  return async (assetId) => {
    const data = await deleteAsset(assetId);
    return data.value;
    // return deletedAsset;
  }
};

export const useCreateAsset = () => {
  const createAsset = useActions(actions.createAsset);

  return async ({image, title}) => {
    return await createAsset({image, title});
  }
};

export const useUpdateAsset = () => {
  const updateAsset = useActions(actions.updateAsset);
  return async ({assetId, title}) => {
    return await updateAsset({assetId, title});
  }
};

export const useLocalAssets = () => {
  return useSelector(state => state.cms.assets);
};

export default {
  useAsset,
  useAssets,
  useDeleteAsset,
  useCreateAsset,
  useUpdateAsset,
  useLocalAssets
};