import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const VersionContainer = styled.div`
    // color: black;
    // font-weight:
    margin-bottom: 100px;
`;

const Title = styled(Typography)`
    padding-bottom: 20px;
    color: black;
    text-decoration: underline;
`;

const Header = styled.div`
    font-weight: 500;
    color: black;
`;

const List = styled.ul`
    margin-bottom: 20px;
`;

const ListItem = styled.li`
`
/*
    Types of changes
    - 'Added' for new features.
    - 'Changed' for changes in existing functionality.
    - 'Deprecated' for soon-to-be removed features.
    - 'Removed' for now removed features.
    - 'Fixed' for any bug fixes.
    - 'Security' in case of vulnerabilities.
*/

const ChangeLog = ({
    open,
    onCancel,
  }) => {
      return (
          <Dialog
            open={open}
            scroll="paper"
          >
            <DialogTitle>Change Log</DialogTitle>
            <DialogContent dividers={true}>
          {/* <DialogContentText
            tabIndex={-1}
          > */}
          <VersionContainer>
            <Title variant="h5">Version 1.2.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Homepage Content: is now manageable and can be sorted by drag and drop
                </ListItem>
                <ListItem>
                  Contact Page Content: is now manageable
                </ListItem>
                <ListItem>
                  About Us Page Content: is now manageable
                </ListItem>
              </List>
              <Header>Fixed</Header>
              <List>
                <ListItem>
                  Spec/CAD File title missing causing issue in database
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h5">Version 1.1.0</Title>
            <Header>Added</Header>
              <List>
                <ListItem>
                  Materials are now separated by Indoor and Outdoor 
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
            <Title variant="h5">Version 1.0.10</Title>
            <Header>Fixed</Header>
              <List>
                <ListItem>
                  Under the hood improvements to API and Data
                </ListItem>
              </List>
          </VersionContainer>
          <VersionContainer>
              <Title variant="h5">Version 1.0.9</Title>
              <Header>Fixed</Header>
              <List>
                <ListItem>
                  PDFs were titled as 'untitled' in database.
                </ListItem>
              </List>
            </VersionContainer>
            <VersionContainer>
              <Title variant="h5">Version 1.0.8</Title>
              <Header>Added</Header>
              <List>
                <ListItem>
                  Material Images: materials now have a new image limit of 20.
                </ListItem>
                <ListItem>
                  Form layout: changed to accomodate new image limit.
                </ListItem>
                <ListItem>
                  Loading: animation now shows when content is loading.
                </ListItem>
              </List>
            </VersionContainer>

            <VersionContainer>
              <Title variant="h5">Version 1.0.7</Title>
              <Header>Added</Header>
              <List>
                  <ListItem>Crop Images: When adding a new image. An edit button will appear allowing a 3:2 aspect ratio crop. You can only do this
                    when adding an image. The option will not show after the image has been published. If the original image is smaller than the crop box,
                    no crop will be made.
                  </ListItem>
              </List>
            </VersionContainer>
            <VersionContainer>
            <Title variant="h5">Version 1.0.6</Title>
            <Header>Added</Header>
            <List>
                <ListItem>Link Product: You can now link a product from the collection page</ListItem>
            </List>
            <Header>Fixed</Header>
            <List>
                <ListItem>Year/Description: Now leaving year and description empty will save correctly</ListItem>
                <ListItem>Sort Alphabetically: Adding or modifying a product now sorts the collection alphabetically</ListItem>
                <ListItem>Seeing Multiple: An error with the cache was showing multiples of products. After signing out the cache will clear.</ListItem>
            </List>
            </VersionContainer>

            <VersionContainer>
            <Title variant="h5">Version 1.0.5</Title>
            <Header>Added</Header>
            <List>
                <ListItem>Change Log to keep track of what has been fixed or added.</ListItem>
            </List>
            </VersionContainer>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Close
          </Button>
        </DialogActions>
          </Dialog>
      );
  };

  export default ChangeLog;