import React from 'react';
import { 
  Box,
  Container,
  Divider
} from '@material-ui/core';
import { Toolbar } from './components';

const PageWrapper = ({ title, actions, divider, label, children }) => (
  <Box
    component="main"
    style={{
      flexGrow: 1,
      paddingTop: 0,
      paddingBottom: 64
    }}
  >
    <Toolbar title={title} actions={actions} label={label}/>
    <Container maxWidth={false}>
      {children}
    </Container>
  </Box>
);

export default PageWrapper;