import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import get from 'lodash/fp/get';
import Link from "next/link";

import { useSelector } from "react-redux";

const FlexDivider = styled.span`
	flex: 1;
`;

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem);

const Event = withStyles({
  root: props => ({
    padding: '15px 10px',
    marginTop: '20px',
    marginBottom: '16px',
    backgroundColor: '#F8F9FC'
  })
})(Typography);

const EventItem = ({ classes, content, isLast }) => {
  // console.log(content)
  const currentUser = useSelector(state => state.user.sub);
  const creatorId = get('creator.id', content);
  const creatorName = creatorId === currentUser ? "You" : get('creator.name', content);
  const contacts = get('payload.attendeeContacts', content) || [];
  const employees = get('payload.attendeeEmployees', content) || [];
  const companies = get('payload.attendeeCompanys', content) || [];
  const date = moment(content.createdAt).calendar();
  const action = get('action', content);
  const title = get('payload.name', content);
  const time_event = get('payload.date', content);

  return (
    <TimelineItem>
      <TimelineSeparator className={classes.seperator}>
        <TimelineDot color="primary" variant="outlined">
          <EventIcon color="primary" />
        </TimelineDot>
        <If condition={!isLast}>
          <TimelineConnector />
        </If>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Paper elevation={0} className={classes.paper}>
          <Box display="flex" flexDirection="row" alignItems="center" flex="1">
            <Typography variant="body1" className={classes.title}>
              <strong>{creatorName}</strong>
              &nbsp;{action.toLowerCase()} an event: 
              &nbsp;<b>{title}</b>
            </Typography>
            <FlexDivider />
            <Typography variant="body1">{date}</Typography>
          </Box>
          <Event>
            Date: <b>{time_event}</b>
            <If condition={contacts.length}>
              <br />
              <br />
              Contacts: 
              <For each="contact" of={contacts}>
                <Link
                  href={{
                    pathname: `/dash`,
                    query: {id: contact.id, type: 'contact', action: 'view'} 
                  }}
                  as={`/customers/contact/${contact.id}`}
                  passHref
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: '4px 4px' }}
                  >
                    {contact.name}
                  </Button>
                </Link>
              </For>
            </If>
            <If condition={companies.length}>
              {console.log('companies from EventItem', companies)}
              <br />
              <br />
              Companies: 
              <For each="company" of={companies}>
                <Link 
                  href={{ 
                    pathname: '/dash',
                    query: {id: company.id, type: 'company', action: 'view'} 
                }} 
                  as={`/customers/company/${company.id}`} 
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: '4px 4px' }}
                  >
                    {company.name}
                  </Button>
                </Link>
              </For>
            </If>
            <If condition={employees.length}>
              <br />
              <br />
              Employees: 
              <For each="employee" of={employees}>
                <Typography variant="body1" component="span"><b>&nbsp;{employee.name}&nbsp;&nbsp;</b></Typography>
              </For>
            </If>
            
          </Event>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
};

export default EventItem;