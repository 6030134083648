import React, {useState} from "react";
import get from "lodash/fp/get";
import { Auth } from "aws-amplify";
import styled from "styled-components";
import { Form, Field as FinalField, FormSpy } from "react-final-form";
import { FieldArray } from 'react-final-form-arrays';
import mutators from "final-form-arrays";
import moment from 'moment';
import clsx from 'clsx';

import { makeStyles, Typography, Tooltip, IconButton } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import InputField from '../shared/input-field';
import ContactSelectField from '../contact-select-field';
import CompanySelectField from '../company-select-field';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontWeight: '500',
  },
  subheading: {
    paddingLeft: 85,
    paddingRight: 85,
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 20
  },
  subtitle: {
    textAlign: 'center',
  },
  selectFields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 10
  },
  lastWeekValues: {
    paddingRight: 35
  },
  nextWeekValues: {
    paddingLeft: 35
  },
  dropdown: {
    width: '350px'
  },
  textarea: {
    minHeight: '200px',
    height: 'auto'
  },
  contactList: {
    marginTop: 10
  },
  contacts: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 4
  },
  weekly: {
    marginTop: 30
  },
  clearButton: {
    width: '150px',
    display: 'flex',
    justifyContent: 'center'
  }
}));
const FormLayout = styled("form")`
  flex: 1;
  width: 800px;
  margin: 0 auto;
  display: flex;
  padding: 10px 12px;
  padding-bottom: 10px;
`;

const Multiline = styled(InputField)`
  width: 80%;
  max-width: 800px;
`;

const Field = ({item, lg, xl, ...props}) => (
  <Grid item lg={lg} xl={xl}>
    <FinalField {...props} />
  </Grid>
);

const SalesMeetingForm = ({ id, setFormState, initialValues, setInitialValues }) => {
  const classes = useStyles();
  const username = Auth.user.attributes.name;
  const date = moment().format("MMMM Do, YYYY");
  const [lastWeekLinkType, setLastWeekLinkType] = useState('CONTACT');
  const [nextWeekLinkType, setNextWeekLinkType] = useState('CONTACT');
  const handleLastWeekToggle = (value) => {
    if(lastWeekLinkType == "CONTACT" && value == "COMPANY") {
      setLastWeekLinkType("COMPANY")
    } else if (lastWeekLinkType == "COMPANY" && value == "CONTACT") {
      setLastWeekLinkType("CONTACT")
    }
  };
  const handleNextWeekToggle = (value) => {
    if(nextWeekLinkType == "CONTACT" && value == "COMPANY") {
      setNextWeekLinkType("COMPANY")
    } else if (nextWeekLinkType == "COMPANY" && value == "CONTACT") {
      setNextWeekLinkType("CONTACT")
    }
  };
  return (
    <Form
      onSubmit={() => {}}
      initialValues={initialValues}
      mutators={mutators}
    >
    {({form}) => (
    <FormLayout>
      <FormSpy
        subscription={{ values:true, pristine:true }}
        onChange={(state) => {
          setFormState(state)
        }}
      />
      <Grid direction={"column"} container spacing={3} id={id}>
        <div className={classes.subheading}>
          <Typography className={classes.title} variant="subtitle2">SALESPERSON: {username}</Typography>
          <Typography className={classes.title} variant="subtitle2">DATE: {date}</Typography>
        </div>
        <Typography className={clsx(classes.title, classes.weekly)} variant="subtitle1">WEEKLY OUTREACH</Typography>
        <div className={classes.selectFields}>
          <FieldArray container spacing={4} name="lastWeekValues">
            {({fields}) => (
              <div style={{paddingRight: 35}}>
                <Typography className={classes.subtitle} variant="subtitle1">LAST WEEK</Typography>
                  <ToggleButtonGroup size="small" color="primary" style={{marginTop: 12}} exclusive value={lastWeekLinkType} onChange={(e,value) => handleLastWeekToggle(value)}>
                    <ToggleButton value="CONTACT" >Contact</ToggleButton>
                    <ToggleButton value="COMPANY" >Company</ToggleButton>
                  </ToggleButtonGroup> 
                <Choose>
                  <When condition={lastWeekLinkType === 'CONTACT'}>
                    <Field
                      className={classes.dropdown}
                      sm={9}
                      component={ContactSelectField}
                      name="last-week-contact"
                      marginTop="8px"
                      variant="outlined"
                      getOptionLabel={(value) => `${get("full_name",value)} ${get('company',value)? `(${get('company.name', value)})` : ''}`}
                      onChange={(value) => fields.push(`${get("full_name",value)} ${get('company',value)? `(${get('company.name', value)})` : ''}`)}
                    />
                  </When>
                  <When condition={lastWeekLinkType === 'COMPANY'}>
                    <Field
                      className={classes.dropdown}
                      sm={9}
                      component={CompanySelectField}
                      name="last-week-company"
                      marginTop="8px"
                      variant="outlined"
                      getOptionLabel={(value) => `${get("name", value)}`}
                      onChange={(value) => fields.push(`${get("name", value)}`)}
                    />
                  </When>
                </Choose>
                <div className={classes.textarea}>
                {fields.value? fields.value.map((name, index)=> (
                  <div className={classes.contacts}>
                    <Typography key={name}>{`${name}`}</Typography>
                    <Tooltip title="Remove from list" placement="top">
                      <IconButton
                        style={{padding: 5}}
                        aria-label="remove" 
                        component="span" 
                        fontSize="small" 
                        onClick={() => fields.remove(index)}
                      >
                        <ClearIcon fontSize="small"/>
                      </IconButton>
                    </Tooltip>
                  </div>
                )): null}
                </div>
              </div>
            )}
          </FieldArray>

          <FieldArray container spacing={4} name="nextWeekValues">
            {({fields}) => (
              <div style={{paddingLeft: 35}}>
                <Typography className={classes.subtitle} variant="subtitle1">THIS WEEK</Typography>
                  <ToggleButtonGroup size="small" color="primary" style={{marginTop: 12}} exclusive value={nextWeekLinkType} onChange={(e,value) => handleNextWeekToggle(value)}>
                    <ToggleButton value="CONTACT">Contact</ToggleButton>
                    <ToggleButton value="COMPANY">Company</ToggleButton>
                  </ToggleButtonGroup> 
                <Choose>
                  <When condition={nextWeekLinkType === 'CONTACT'}>
                    <Field
                      className={classes.dropdown}
                      sm={9}
                      component={ContactSelectField}
                      name="next-week-contact"
                      marginTop="8px"
                      variant="outlined"
                      getOptionLabel={(value) => `${get("full_name",value)} ${get('company',value)? `(${get('company.name', value)})` : ''}`}
                      onChange={(value) => fields.push(`${get("full_name",value)} ${get('company',value)? `(${get('company.name', value)})` : ''}`)}
                    />
                  </When>
                  <When condition={nextWeekLinkType === 'COMPANY'}>
                    <Field
                      className={classes.dropdown}
                      sm={9}
                      component={CompanySelectField}
                      name="next-week-company"
                      marginTop="8px"
                      variant="outlined"
                      getOptionLabel={(value) => `${get("name", value)}`}
                      onChange={(value) => fields.push(`${get("name", value)}`)}
                    />
                  </When>
                </Choose>
                {fields.value? fields.value.map((name, index)=> (
                  <div className={classes.contacts}>
                    <Typography key={name}>{`${name}`}</Typography>
                    <Tooltip title="Remove from list" placement="top">
                      <IconButton
                        style={{padding: 5}}
                        aria-label="remove" 
                        component="span" 
                        fontSize="small" 
                        onClick={() => fields.remove(index)}
                      >
                        <ClearIcon fontSize="small"/>
                      </IconButton>
                    </Tooltip>
                  </div>
                )): null}
              </div>
            )}
          </FieldArray>
        </div>

        <Field
          component={Multiline}
          rows={8}
          multiline
          name="notes"
          fullWidth
          label="NOTES:"
          marginTop="8px"
          parse={value => (value == "" ? "" : value)}
        />
        <Field
          component={Multiline}
          rows={8}
          multiline
          name="scheduledPresentations"
          fullWidth
          marginTop="25px"
          label="SCHEDULED PRESENTATIONS OR MEETINGS:"
          parse={value => (value == "" ? "" : value)}
        />
        <Field
          component={Multiline}
          rows={8}
          multiline
          name="quotesAndLeads"
          fullWidth
          label="PROMISING INFORMAL QUOTES / GOOD LEADS:"
          parse={value => (value == "" ? "" : value)}
        />
        <Button
          variant="outlined"
          color="primary"
          className={classes.clearButton}
          onClick={() => {
            setInitialValues({notes: "", quotesAndLeads: "", scheduledPresentations: "", lastWeekValues: [], nextWeekValues: []});
          }}
        >
          Clear All Fields
        </Button>
      </Grid>
    </FormLayout>
    )}
  </Form> 
  );
};

export default SalesMeetingForm;