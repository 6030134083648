import React, { useState, useEffect } from "react";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import get from 'lodash/fp/get';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';

const makeJSDateObject = (date) => {
  if (moment.isMoment(date)) {
    return moment(date).clone().toDate();
  } else {
    return new Date(date.getTime());
  }
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    '& .MuiInputLabel-outlined': {
      zIndex: 0
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(12px, -6px) scale(0.70)'
		}
  },
  boxDatePicker: {
    display: 'flex',
    lineHeight: '1.18em',
    margin: theme.spacing(1),
    borderRadius: '4px',
    border: '1px rgba(0, 0, 0, 0.23) solid',
    cursor: 'pointer',
    padding: '10.5px 14px',
    position: 'relative',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      justifyContent: 'center',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '& .MuiInput-underline:before': {
      all: 'unset'
    },
    '& .MuiInput-underline:after': {
      all: 'unset'
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem'
    },
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: theme.spacing(1),
      width: '90px',
      backgroundColor: theme.palette.common.white
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiFilledInput-underline:before': {
      all: 'unset'
    },
    '& .MuiFilledInput-underline:after': {
      all: 'unset'
    },
    '& label': {
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      paddingLeft: '4px',
      paddingRight: '2px',
      transform: 'translate(14px, -6px) scale(0.75)',
      transformOrigin: 'top left',
      color: '#152939',
      fontSize: '16px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      letterSpacing: '-0.05px',
      lineHeight: 1,
      background: theme.palette.common.white
    }
  },
}));

const FilterByDate = ({ handleFilter }) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const today = new Date();
  // const _startThisWeek = startOfDay(startOfWeek(today));
  // const _endThisWeek = startOfDay(endOfWeek(today));
  const _startLast7Days = startOfDay(subDays(today, 6));
  const _startThisMonth = startOfDay(startOfMonth(today));
  // const _endThisMonth = endOfDay(endOfMonth(today));
  const _startLast30Days = startOfDay(subDays(today, 29));
  const _endLastMonth = endOfDay(subDays(_startThisMonth, 1));
  const _startLastMonth = startOfDay(startOfMonth(_endLastMonth));

  const options = [
    // { name: 'This Week', date: { start: _startThisWeek, end: _endThisWeek } },
    { name: 'Last 7 Days', date: { start: _startLast7Days, end: moment().endOf('day').utc() } },
    { name: 'This Month', date: { start: _startThisMonth, end: moment().endOf('day').utc() } },
    { name: 'Last 30 Days', date: { start: _startLast30Days, end: moment().endOf('day').utc() } },
    { name: 'Last Month', date: { start: _startLastMonth, end: _endLastMonth } },
    { name: 'Custom', date: { start: startOfDay(today), end: today } },
  ];

  const parseDate = date => ({
    start: `${date.start.toISOString(true)}`,
    end: `${date.end.toISOString(true)}`,
  });

  const handleInitialize = () => {
    const date = parseDate(options[0].date);
    setValue({ ...options[0], date });
    handleFilter(date);
  };

  useEffect(() => handleInitialize(), []);

  const handleChange = event => {
    const { value } = event.target;
    const date = parseDate(value.date);
    handleFilter(date);
    setValue({ ...value, date });
  };

  const handleChangeCustom = (date, type) => {
    let parsed;
    if (type === 'start') {
      parsed = {
        ...value.date,
        start: `${startOfDay(makeJSDateObject(date)).toISOString(true)}`
      };
    }
    if (type === 'end') {
      parsed = {
        ...value.date,
        end: `${endOfDay(makeJSDateObject(date)).toISOString(true)}`
      };
    }
    handleFilter(parsed);
    setValue({ ...value, date: parsed });
  };

  return (
    <Choose>
      <When condition={value.name === 'Custom'}>
        <Box className={classes.boxDatePicker}>
          <label>Date</label>
          <DateRangeRoundedIcon fontSize="medium" color="primary" />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              value={get('date.start', value)}
              onChange={(date) => handleChangeCustom(date, 'start')}
              disableFuture={true}
              inputVariant="filled"
              orientation="landscape"
              variant="inline"
              format="MM-DD-YYYY"
              autoOk
            />
            <Typography>-</Typography>
            <DatePicker
              value={get('date.end', value)}
              onChange={(date) => handleChangeCustom(date, 'end')}
              disableFuture={true}
              minDate={get('date.start', value)}
              inputVariant="filled"
              orientation="landscape"
              variant="inline"
              format="MM-DD-YYYY"
              autoOk
            />
          </MuiPickersUtilsProvider>
          <IconButton
            children={<ClearIcon fontSize="small" />}
            onClick={handleInitialize}
          />
        </Box>
      </When>
      <Otherwise>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="select-date-activity">Date</InputLabel>
          <Select
            id="select-date-activity"
            value={value}
            onChange={handleChange}
            label="Date"
            renderValue={value => value.name}
          >
            <For each="option" index="index" of={options}>
              <MenuItem value={option} key={index}>{option.name}</MenuItem>
            </For>
          </Select>
        </FormControl>
      </Otherwise>
    </Choose>
  )
}

export default FilterByDate;