import {
  Box,
  Typography
} from '@material-ui/core';

const Toolbar = ({title, actions, label, ...props}) => (
  <Box {...props}>
    <Box
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        // m: -1,
        backgroundColor: '#FFF',
        // mt: 0,
        paddingTop: 32,
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 16
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <If condition={label}>
          <Typography
            style={{ margin: 8 }}
            variant="subtitle"
          >
            {label}
          </Typography>
        </If>
        <Typography
          style={{ margin: 8 }}
          variant="h5"
        >
          {title}
        </Typography>
      </Box>
      <Box style={{ margin: 8, display: 'flex', alignItems: 'center' }}>
        {actions}
      </Box>
    </Box>
  </Box>
);

export default Toolbar;