import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import Box from '@material-ui/core/Box';
import includes from 'lodash/includes';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

import { useUserAttributes, useUserGroups } from '../../utils/use-user';

import { QuickActions, Activity } from './components';
import { MyTasks } from '../task-container'; 
import PageWrapper from '../../layouts/page-wrapper/PageWrapper';

const Title = styled.span`
    font-size: 24px;
    font-weight: 500;
    color: #2F2F2F;
`;

const FlexDivider = styled.span`
	flex: 1;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  paper: {
    height: 300
  },
  header: {
    flexDirection: 'column',
    alignItems: 'flex-start'
    // height: 80,
    // border: '1px solid black'
  },
  grid: {
    // border: '1px solid red'
  },
  toolbar: {
    width: 'inherit',
    paddingRight: 20
  },
  topRow: {
    flexDirection: 'row',
    display: 'flex'
  },
  divider: {
    marginTop: 15
  },
  date: {
    fontWeight: 500,
    marginTop: 15
  },
  items: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap'
    }
  }
}))

const Dashboard = () => {
  const classes = useStyles();
  const attributes = useUserAttributes();
  const name = attributes.name ? attributes.name : attributes.email;
  const hour = moment().hour();

  let greeting = 'Good morning';
  if (hour > 17){
    greeting = "Good evening";
  }
  else if (hour > 12){
    greeting = "Good afternoon";
  }

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [curTeamToken, setCurTeamToken] = useState(null);
  const [curMyToken, setCurMyToken] = useState(null);

  const groups = useUserGroups();
  const isAdmin = includes(groups, 'admin');
  const isSales = includes(groups, 'sales');

  // return(
  //     <Box flexGrow={1} className={classes.root}>
  //       {/* <Toolbar className={classes.header} disableGutters>
  //         <Title>Hello {greeting},</Title>
  //       </Toolbar> */}
  //       <Grid className={classes.header} container>
  //         <Grid item className={classes.toolbar}>
  //           <Grid item className={classes.topRow}>
  //             <Typography variant="h4" gutterBottom>Dashboard</Typography>
  //             <FlexDivider />
  //             <Typography variant="subtitle1" className={classes.date}>{`Today's Date: ${moment().format("MMMM Do, YYYY")}`}</Typography>
  //           </Grid>
  //           <Typography variant="subtitle1">{greeting}, {name}!</Typography>
  //         </Grid>
  //       </Grid>
        
  //       <Divider className={classes.divider}/>
  //       <QuickActions />
  //       <Grid container className={classes.grid}>
  //         <Grid item xs={12} container spacing={2} className={classes.items}>
  //           <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
  //             <If condition={isAdmin || isSales}>
  //               <Activity />
  //             </If>
  //           </Grid>
  //           <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
  //             <If condition={isAdmin || isSales}>
  //               <MyTasks setTriggerFetch={setTriggerFetch} triggerFetch={triggerFetch} setCurToken={setCurMyToken} curToken={curMyToken} setCurTeamToken={setCurTeamToken} showCreateButton/>
  //             </If>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Box>   
  // )

  return(
    <PageWrapper
      title={`${greeting}, ${name ? name : ""}!`}
      label="Dashboard"
      actions={
        <>
          <Typography variant="subtitle1">{`Today's Date: ${moment().format(
            "MMMM Do, YYYY"
          )}`}</Typography>
        </>
      }
    >
      <Grid container spacing={2} style={{marginTop: 0, marginBottom: 0}} direction="row" justifyContent="flex-start">
        <Grid item xs={12}>
          <QuickActions />
        </Grid>
        <If condition={isAdmin || isSales}>
          <Grid item xs={12} lg={7} xl={7}>
            <Activity />
          </Grid>
        </If>
        <If condition={isAdmin || isSales}>
          <Grid item xs={12} lg={5} xl={5}>
            <MyTasks setTriggerFetch={setTriggerFetch} triggerFetch={triggerFetch} setCurToken={setCurMyToken} curToken={curMyToken} setCurTeamToken={setCurTeamToken} showCreateButton/>
          </Grid>
        </If>
      </Grid>
    </PageWrapper>

  )
};

export default Dashboard;