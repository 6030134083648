import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import get from 'lodash/fp/get';
import lowerCase from 'lodash/fp/lowerCase';
import Link from "next/link";

import { useSelector } from "react-redux";

const FlexDivider = styled.span`
	flex: 1;
`;

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem);

const Note = withStyles({
  root: props => ({
    padding: '15px 10px',
    marginTop: '20px',
    marginBottom: '16px',
    textDecoration: props.delete? 'line-through': '',
    backgroundColor: '#F8F9FC'
  })
})(Typography);

const NoteItem = ({ classes, content, isLast }) => {
  const currentUser = useSelector(state => state.user.sub);
  const creatorId = get('creator.id', content);
  const creatorName = creatorId === currentUser? "You" : get('creator.name', content);

  const target = lowerCase(get('payload.target', content));
  const targetId = get('payload.targetId', content);
  const targetName = get('payload.name', content);
  const note = get('payload.note', content);
  const date = moment(content.createdAt).calendar();
  const action = get('action', content);
  return (
    <TimelineItem>
      <TimelineSeparator className={classes.seperator}>
        <TimelineDot color="primary" variant="outlined">
          <EditIcon color="primary" />
        </TimelineDot>
        <If condition={!isLast}>
          <TimelineConnector />
        </If>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Paper elevation={0} className={classes.paper}>
          <Box display="flex" flexDirection="row" alignItems="center" flex="1">
            <Typography variant="body1" className={classes.title}>
              <strong>{creatorName}</strong> 
              <Choose>
                <When condition={action === 'CREATED'}>
                &nbsp;added a note to:
                </When>
                <When condition={action === 'DELETED'}>
                &nbsp;deleted a note in:
                </When>
              </Choose>
            </Typography>
            <Link 
              href={{ 
                pathname: `dash`,
                query: {id: targetId, type: target, action: 'view'}
              }} 
              as={`/customers/${target}/${targetId}`} 
              passHref 
            >
              <Button
                variant="outlined"
                color="primary"
                style={{ margin: '0px 10px'}}
              >
                {targetName}
              </Button>
            </Link>
            <FlexDivider />
            <Typography variant="body1">{date}</Typography>
          </Box>
          <Note delete={action === 'DELETED'}>{note}</Note>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
};

export default NoteItem;