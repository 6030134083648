import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import Face from "@material-ui/icons/Face";
import Exit from "@material-ui/icons/ExitToApp";
import Link from "next/link";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MenuItem from "@material-ui/core/MenuItem";
import PageIcon from "@material-ui/icons/InsertDriveFileOutlined";
import grey from "@material-ui/core/colors/grey";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { postDeploy } from '../../api';
import { useRouter } from 'next/router'

import FormDialog from '../shared/form-dialog';
import AlertForm from '../shared/alert-form';
import ChangeLog from '../shared/change-log';

import Roadmap from "../roadmap/Roadmap";
import Construction from '../construction/Construction';

import { useUserLogout } from '../../utils/use-user';
import { MATERIAL_PAGE_ID } from '../../utils/constants';

const Root = styled.div`
  width: 225px;
  height: 100%;
  background: #F8F9FA;
  display: flex;
  flex-direction: column;
`;

const StyledBox = styled(Box)`
  // color: ${grey[400]};
  color: #2a3039;
`;

const Trigger = styled(Button)`
    && {
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      width: -webkit-fill-available;
    }
`;

const Version = styled(Button)`
    && {
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      // background-color: #55efc4;
      // color: #FFF;
      width: -webkit-fill-available;
    }
`;

const Report = styled(Button)`
    && {
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      // background-color: #55efc4;
      // color: #FFF;
      width: -webkit-fill-available;
    }
`;

const RoadmapButton = styled(Button)`
    && {
      margin-bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      // background-color: #55efc4;
      // color: #FFF;
      width: -webkit-fill-available;
    }
`;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [dialog, updateDialog] = useState(null);
  const router = useRouter();

  const logoutUser = useUserLogout();

  //remove later
  const [logOpen, setLogOpen] = useState(false);
  const [roadOpen, setRoadOpen] = useState(false);

  const handleDeploy = async () => {
    setDisableButton(true);
    const deployed = await postDeploy();
    if(deployed.status == 200) setOpen(true);
    updateDialog(null);
    setDisableButton(false);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async () => {
    console.log('Logged out!');
    Auth.signOut();
    await router.push('/');
    localStorage.clear();
    logoutUser();
  }

  const pages = [
    { label: "Home", href: "/home" },
    {
      label: "Products",
      href: "/pages/[page_id]/collections/[collection_id]",
      as: "/pages/7uruqd0qREdvDGT6mvJSmM/collections/undefined"
    },
    {
      label: "Materials",
      href: "/pages/[page_id]/collections/[collection_id]",
      as: `/pages/7EfVcaWufa4v65NW1WDY7F/collections/undefined`
    },
    { label: "About Us", href: "/about-us" },
    { label: "Contact", href: "/contact" }
  ];

  const locations = [
    {
      label: "Contacts",
      href: "/customers/contacts",
      // as: "/locations/los_angeles"
    },
    {
      label: "Companies",
      href: "/customers/companies"
    },
    // {
    //   label: "Groups",
    //   href: "/customers/groups"
    // }
    // {
    //   label: "San Francisco",
    //   href: "/locations/[city]",
    //   as: "/locations/san_francisco"
    // }
  ];
  // console.log(Auth.user.username);
  return (
    <Root>
      {/* <Construction open={true}/> */}
      <StyledBox p={2}>
        <List
          subheader={
            <ListSubheader component="div" color="inherit">
              Customers
            </ListSubheader>
          }
        >
          <For each="location" of={locations}>
            <Link key={location.label} href={location.href} as={location.as}>
              <MenuItem dense component="a">
                <ListItemIcon>
                  <PageIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={location.label} />
              </MenuItem>
            </Link>
          </For>
        </List>
      </StyledBox>
      <StyledBox p={2}>
        <List
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              color="inherit"
            >
              Website
            </ListSubheader>
          }
        >
          <For each="page" of={pages}>
            <Link key={page.label} href={page.href} as={page.as}>
              <MenuItem dense component="a">
                <ListItemIcon>
                  <PageIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={page.label} />
              </MenuItem>
            </Link>
          </For>
        </List>
      </StyledBox> 
      <StyledBox mt="auto" mb={4} p={2}>
        <List>
          
        <Trigger
          variant="contained"
          color="primary"
          startIcon={<CloudUploadIcon />}
          // onClick={() => handleDeploy()}
          onClick={() => updateDialog("deploy-website")}
          >
            Schedule Deploy
          </Trigger>

          <Version
          variant="contained"
          onClick={() => setLogOpen(true)}
          >
          Version 1.2.0
          </Version>

          <RoadmapButton
          variant="contained"
          onClick={() => setRoadOpen(true)}
          >
          Roadmap
          </RoadmapButton>

          <Report
          variant="contained"
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdSH_k1SnMax5uVp0qbTrUDrcGrqgyrqblznfX4GhZ8YnhK8A/viewform"
          >
          Report Issue
          </Report>

          
          <Link href="/">
          <MenuItem dense component="a">
            {/* <ListItemIcon>
              <Face />
            </ListItemIcon> */}
            <ListItemText primary={Auth.user.username} />
          </MenuItem>
          </Link>
          <MenuItem dense component="a" onClick={() => handleLogout()}>
          {/* <MenuItem dense component="a" onClick={() => console.log('Signing out!!')}> */}
            <ListItemIcon>
              <Exit />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </List>
      </StyledBox>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Deployment Scheduled!
        </Alert>
      </Snackbar>
      <FormDialog
        Form={AlertForm}
        id="deploy-website"
        title="Deploy website"
        initialValues={{ companyName: "Flexform" }}
        open={dialog === "deploy-website"}
        onCancel={() => updateDialog(null)}
        onSubmit={handleDeploy}
        disableButton={disableButton}
        submitLabel="Yes, deploy" />
        <ChangeLog open={logOpen} onCancel={() => setLogOpen(false)}/>
        <Roadmap open={roadOpen} onCancel={() => setRoadOpen(false)} />
    </Root>
  );
};

export default Sidebar;