import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import get from 'lodash/fp/get';
import Link from "next/link";

import { useSelector } from "react-redux";

const FlexDivider = styled.span`
	flex: 1;
`;

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem);

const ContactItem = ({ classes, content, isLast }) => {
  const currentUser = useSelector(state => state.user.sub);
  const creatorId = get('creator.id', content);
  const creatorName = creatorId? creatorId === currentUser? "You" : get('creator.name', content) : '33sixty.com';
  
  const contactId = get('activityId', content);
  const contactName = get('payload.name', content);
  const date = moment(content.createdAt).calendar();
  const action = get('action', content);
  return (
    <TimelineItem>
      <TimelineSeparator className={classes.seperator}>
        <TimelineDot color="primary" variant="outlined">
          <PersonIcon color="primary" />
        </TimelineDot>
        <If condition={!isLast}>
          <TimelineConnector />
        </If>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Paper elevation={0} className={classes.paper}>
          <Box display="flex" flexDirection="row" alignItems="center"  flex="1">
            <Typography variant="body1" className={classes.title}>
              <strong>{creatorName}</strong> 
              <Choose>
                <When condition={action === 'CREATED'}>
                &nbsp;created a new contact:
                </When>
                <When condition={action === 'DELETED'}>
                &nbsp;deleted a contact:
                </When>
              </Choose>
            </Typography>
            <Choose>
              <When condition={action === 'DELETED'}>
                <Typography variant="body1"><b>&nbsp;{contactName}</b></Typography>
              </When>
              <Otherwise>
                <Link 
                  href={{ 
                    pathname: '/dash',
                    query: {id: contactId, type: 'contact', action: 'view'} 
                  }} 
                  as={`/customers/contact/${contactId}`} 
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ margin: '0px 10px'}}
                    disabled={action === 'DELETED'}
                  >
                    {contactName}
                  </Button>
                </Link>
              </Otherwise>
            </Choose>
            <FlexDivider />
            <Typography variant="body1">{date}</Typography>
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
};

export default ContactItem;