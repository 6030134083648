import { useState, useEffect } from 'react';
import get from 'lodash/fp/get';
import { API, graphqlOperation } from "aws-amplify";

import { default as useActions } from '../use-actions';
import { default as actions } from '../../redux/actions/amplifyActions';
import { listActivitys, onCreateActivity } from '../../src';

export const useTeamActivity = (filter, token, triggerFetch) => {
  // console.log({filter})
  const { listActivityByCreatedAt, listActivityByCreator } = useActions(actions);
  const [activity, setActivity] = useState([]);
  const [prevFilter, setPrevFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [total, setTotal] = useState(null);

  const createdAt_start = get('createdAt.start', filter);
  const createdAt_end = get('createdAt.end', filter);

  const fetchActivity = async () => {
    if (prevFilter !== filter) {
      setActivity([]);
    }

    try {
      setIsLoading(true);
      if (filter.employee === 'All') {
        const data = await listActivityByCreatedAt({
          limit: 10,
          nextToken: token,
          type: 'ACTIVITY',
          sortDirection: 'DESC',
          createdAt: { between: [createdAt_start, createdAt_end] }
        });
        // console.log(data)
        if (token) {
          setActivity(prev => prev.concat(data.value.items))
        } else {
          setActivity(data.value.items);
        }
        setNextToken(data.value.nextToken);
      } else {
        const data = await listActivityByCreator({
          limit: 10,
          nextToken: token,
          type: 'ACTIVITY',
          creatorId: filter.employee,
          sortDirection: 'DESC',
          createdAt: { between: [createdAt_start, createdAt_end] }
        });
        // console.log(data)
        if (token) {
          setActivity(prev => prev.concat(data.value.items))
        } else {
          setActivity(data.value.items);
        }
        setNextToken(data.value.nextToken)
      }
      setIsLoading(false);
    } catch (e) {
      // console.log(e);
      setIsLoading(false);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    setPrevFilter(filter);
  }

  useEffect(() => { fetchActivity() }, [filter, token, triggerFetch]);
  return [{ activity, nextToken, isLoading, total }];
};

export const usePipelineActivity = async (contactId) => {
  try {
    const { data } = await API.graphql(graphqlOperation(listActivitys, {
      filter: {activityId: { eq: contactId }},
      limit: 500,
      type: 'ACTIVITY'
    }));
    return data;
  }catch(e){
    console.log('error', e)
  }
};

export const useOnCreateActivity = callback => {
  return API.graphql(graphqlOperation(onCreateActivity)).subscribe({
    next: callback,
    error: error => console.log(error)
  });
};