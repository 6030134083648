import React, {useEffect} from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import find from "lodash/fp/find";
import get from "lodash/fp/get";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import useEntries from "../utils/cms/use-entries";
import { useAssets } from '../utils/cms/use-assets';
import Sidebar from '../components/sidebar';
import { Auth } from "aws-amplify";
import LogRocket from 'logrocket';
// import { useRouter } from "next/router";

import Dashboard from '../components/dashboard';
import { getUserAttributes, getUser } from '../utils/use-user';


const Status = styled.span`
    font-size: 24px;
    font-weight: 500;
    color: #FFF;
    margin-top: 30px;
`;

const App = () => {
  const getEntries = useEntries();
  const getAssets = useAssets();
  const router = useRouter();
  
  useEffect(() => {
    LogRocket.identify(get('user.username', Auth), {
      name: get('user.attributes.name', Auth),
      email: get('user.attributes.email', Auth),
    });
    const route = () => setTimeout(() => router.push('/dash'), 1000);
    async function fetchData() {
      await getEntries();
      await getAssets();
      route();
    }
   fetchData();
    return () => clearTimeout(route);  
  }, []); 

    return (
      <Box
        position="absolute" 
        zIndex="2200" 
        width="100vw" 
        display="flex" 
        margin="auto" 
        top="0"
        left="0"
        height="100vh" 
        justifyContent="center" 
        alignItems="center" 
        flexDirection="column" 
        bgcolor="#111827 !important"
      >
        <CircularProgress color="primary.contrastText" style={{color: '#fff'}}/>
        <Status>Connecting...</Status>
      </Box>
    )
};


export default App;