import { useEffect } from "react";

import * as actions from "../../redux/actions/cms";
import useActions from "../use-actions";

// export const useEntries = () => {
//   const getEntries = useActions(actions.getEntries);

//   useEffect(() => {
//     getEntries();
//   });

//   return {
//     id: '0',
//     name: 'test'
//   }
// }

export const useEntries = () => {
  const getEntries = useActions(actions.getEntries);
  return async() => {
    console.log('getting entries');
    getEntries();
  }
}

export default useEntries;
