import React from 'react';
import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';

import moment from 'moment';
import get from 'lodash/fp/get';
import split from 'lodash/fp/split';
import Link from "next/link";
import compact from 'lodash/fp/compact';

import { useSelector } from "react-redux";

const FlexDivider = styled.span`
	flex: 1;
`;

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem);

const Email = withStyles({
  root: props => ({
    padding: '15px 10px',
    marginTop: '20px',
    marginBottom: '16px',
    backgroundColor: '#F8F9FC'
  })
})(Typography);

const LinkedButton = ({ name, id, type }) => (
  <Link 
    href={{ 
      pathname: `/dash`,
      query: {id: id, type: type, action: 'view'} 
    }} 
    as={`/customers/${type}/${id}`} 
    passHref 
  >
    <Button
      variant="outlined"
      color="primary"
      style={{ margin: '0px 10px'}}
    >
      {name}
    </Button>
  </Link>
)

const EmailItem = ({ classes, content, isLast }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentUser = useSelector(state => state.user.sub);
  const creatorId = get('creator.id', content);
  const creatorName = creatorId === currentUser? "You" : get('creator.name', content);

  const date = moment(content.createdAt).calendar();
  const action = get('action', content);
  const contacts = get('payload.recipientContacts', content) || [];
  const companies = get('payload.recipientCompanys', content) || [];
  const others = get('payload.recipientOthers', content);
  const subject = get('payload.subject', content);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <TimelineItem>
      <TimelineSeparator className={classes.seperator}>
        <TimelineDot color="primary" variant="outlined">
          <MailIcon color="primary" />
        </TimelineDot>
        <If condition={!isLast}>
          <TimelineConnector />
        </If>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <Paper elevation={0} className={classes.paper}>
          <Box display="flex" flexDirection="row" alignItems="center" flex="1">
            <Typography variant="body1" className={classes.title}>
              <strong>{creatorName}</strong> 
              <Choose>
                <When condition={action === 'SENT'}>
                &nbsp;sent an email to:
                </When>
              </Choose>
            </Typography>
            <Choose>
              <When condition={contacts.length}>
                <LinkedButton {...contacts[0]} type="contact"/>
              </When>
              <When condition={companies.length}>
                <LinkedButton {...companies[0]} type="company" />
              </When>
            </Choose>
            <FlexDivider />
            <Typography variant="body1">{date}</Typography>
          </Box>
          <Email>
            Subject: <b>{subject}</b>
            <br />
            <br />
            Recipients:
            <For each="contact" of={contacts}>
              <LinkedButton {...contact} type="contact"/>
            </For>
            <For each="company" of={companies}>
              <LinkedButton {...company} type="company"/>
            </For>
            <If condition={others}>
              <Typography variant="body1" component="span">{others}</Typography>
            </If>
          </Email>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  )
};

export default EmailItem;