import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import Box from '@material-ui/core/Box';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import concat from 'lodash/fp/concat';

import { useTeamActivity, useOnCreateActivity } from '../../../../utils/crm/use-team-activity';
import { useEmployees } from '../../../../utils/crm/use-employee';
import {
  ContactItem,
  CompanyItem,
  NoteItem,
  SortBySelector,
  FilterByDate,
  EmailItem,
  EventItem,
  FormItem
} from './components';
import UndrawEmpty from '../../../../static/images/undraw_empty_search.svg';

const FlexDivider = styled.span`
	flex: 1;
`;

const UploadDrawing = styled(UndrawEmpty)`
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  width: 50%;
  max-height: 200px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    minHeight: 300,
    // maxWidth: 800,
  },
  timeline: {
    maxHeight: 800,
    overflowY: 'scroll',
    flexDirection: 'column'
  },
  header: {
    height: 80,
  },
  timelineContent: {
    flex: 12,
    width: '100%',
    // border: '1px solid red'
  },
  timelineDate: {
    textAlign: 'left',
    minWidth: 115,
  },
  seperator: {
    minHeight: 82
  },
  paper: {
    padding: '0px 0px',
    minHeight: 36,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
    // flexGrow: 1,

  },
  title: {
    fontWeight: 400
  },
  note: props => ({
    padding: '15px 10px',
    textDecoration: props.delete ? 'line-through' : 'overline'
  }),
  viewMore: {
    maxWidth: 200,
    margin: '10px auto'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '500px',
    alignItems: 'center'
  },
  otherEmails: {
    padding: theme.spacing(2)
  },
  spacing: {
    marginTop: 10
  }
}));

const Activity = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState({
    employee: 'All',
    createdAt: { start: null, end: null }
  });
  const [curToken, setCurToken] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  let [{ activity, isLoading, nextToken }] = useTeamActivity(filter, curToken, triggerFetch);
  const [{ employees }] = useEmployees();
  let options = concat([{ name: "All", id: "All" }], employees);
  const end = activity.length - 1;

  const handleFilterEmployee = value => {
    setCurToken(null);
    setFilter(prev => ({ ...prev, employee: value }))
  };

  const handleFilterDate = (value) => {
    setCurToken(null);
    console.log('value handleFilterDate ', value)
    setFilter((prev) => (
      { ...prev, createdAt: value }
    ))
    // ^^^ ****** what is this line doing ******* 
    // on date change, this func is called which set's the filte rstate as an object whose first
    // value is the prev object, and then the next one is the createdAt pair with the value being
    // and object with the corresponding start and end date.
  };

  useEffect(() => {
    const createActivitySubscription = useOnCreateActivity(() => { setTriggerFetch(prev => !prev) });
    
    return () => {
      createActivitySubscription.unsubscribe();
    }
  },[]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="h6" gutterBottom>Team Activity Feed</Typography>
        <FlexDivider />
        <FilterByDate handleFilter={handleFilterDate} />
        <SortBySelector
          label="Users"
          handleSort={handleFilterEmployee}
          options={options}
          initialValue={options[0].id}
        />
      </Toolbar>
      <Divider />
      <Box className={classes.timeline}>
        <Choose>
          <When condition={isLoading && !activity?.length}>
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          </When>
          <When condition={!isLoading && !activity?.length}>
            <Box className={classes.loading}>
              <UploadDrawing />
              <Typography align="center">
                No activity found.
              </Typography>
            </Box>
          </When>
          <Otherwise>
            <Timeline >
              <For each="content" index="index" of={activity}>
                <Choose>
                  <When condition={content.activityType === 'CONTACT'}>
                    <ContactItem
                      content={content}
                      classes={classes}
                      isLast={end === index}
                    />
                  </When>
                  <When condition={content.activityType === 'COMPANY'}>
                    <CompanyItem
                      content={content}
                      classes={classes}
                      isLast={end === index}
                    />
                  </When>
                  <When condition={content.activityType === 'NOTE'}>
                    <NoteItem
                      content={content}
                      classes={classes}
                      isLast={end === index}
                    />
                  </When>
                  <When condition={content.activityType === 'EMAIL'}>
                    <EmailItem
                      content={content}
                      classes={classes}
                      isLast={end === index}
                    />
                  </When>
                  <When condition={content.activityType === 'EVENT'}>
                    <EventItem
                      content={content}
                      classes={classes}
                      isLast={end === index}
                    />
                  </When>
                  <When condition={content.activityType === 'FORM'}>
                    <FormItem
                      content={content}
                      classes={classes}
                      isLast={end === index}  
                    />
                  </When>
                </Choose>
              </For>
            </Timeline>
            <If condition={nextToken && activity.length}>
              <Box textAlign="center">
                <Choose>
                  <When condition={isLoading}>
                    <CircularProgress className={classes.viewMore} />
                  </When>
                  <Otherwise>
                    <Button
                      variant="contained"
                      className={classes.viewMore}
                      onClick={() => setCurToken(nextToken)}
                    >
                      View more
                    </Button>
                  </Otherwise>
                </Choose>
              </Box>
            </If>
          </Otherwise>
        </Choose>
      </Box>
    </Paper>
  )
};

export default Activity;