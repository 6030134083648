import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
		'& .MuiInputLabel-outlined': {
			zIndex: 0
		},
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(12px, -6px) scale(0.70)'
		}
	},
}));

export default function SortBySelector({ handleSort, options, initialValue, label }) {
	const classes = useStyles();
	const [value, setValue] = useState('');

	useEffect(() => {
		setValue(initialValue);
		handleSort(initialValue);
	}, []);
	
	const handleChange = (event) => {
		const { value } = event.target;
		setValue(value);
		handleSort(value);
	};

	return (
		<FormControl variant="outlined" className={classes.formControl}>
			<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
			<Select
				value={value}
        onChange={handleChange}
				label={label}
			>
				<For each="option" index="index" of={options}>
					<MenuItem value={option.id} key={index}>{option.name}</MenuItem>
				</For>
			</Select>
		</FormControl>
	);
}

SortBySelector.defaultProps = {
  label: "Sort by"
}
