import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

const Container = styled.div`
  // border: 1px solid red;
  width: 80vw;
  height: 80vh;
  margin: auto;
`;

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
`;

const Message = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 300;
`;

const Header = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Construction = ({
    open,
  }) => {
      return (
          <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
          >
            <Container>
            <ImageContainer src={"/static/images/undraw_construction.png"} />
            <Header>We are under scheduled maintenance</Header>
            <Message>
              We are currently upgrading our system to make it even more useful to you, we will be back in a few hours.
              <br/>
              Thank you for your patience. 
            </Message>
            </Container>
          </Dialog>
      );
  };

  export default Construction;