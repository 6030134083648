import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from '@material-ui/core/Divider';
import { Auth } from "aws-amplify";
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';

import { PDFPreview, DownloadLink } from './PDFPreview';
import { useUpdateEmployee } from '../../utils/crm/use-employee';
import SnackProgress from "../shared/snack-progress";

const styles = {
  PDFViewer: {
    width: '100%',
    height: '750px'
  }
};

const useStyles = makeStyles(() => ({
  PDFViewer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    fontWeight: '500',
  }
}));

const NewReportDialog = ({ id, open, Form, onCancel, newReportDialogInitialValues, setInitialValues }) => {
  const classes = useStyles();
  const date = moment().format("MMMM Do, YYYY");
  const username = Auth.user.attributes.name;
  const userId = Auth.user.username;
  const [preview, setPreview] = useState(false);
  const [formState, setFormState] = useState({
    pristine: null,
    values: null,
    form: null
  });
  const [progress, setProgress] = useState(null);
  const updateEmployee = useUpdateEmployee();

  const handleCancel = () => {
    setPreview(false);
    onCancel();
  };

  const handleSaveDraft = async () => {
    const formFields = {
      notes: formState.notes,
      quotesAndLeads: formState.quotesAndLeads,
      scheduledPresentations: formState.scheduledPresentations,
      lastWeekValues: formState.lastWeekValues,
      nextWeekValues: formState.nextWeekValues
    };

    setProgress({
      text: 'Saving Draft...',
      severity: 'info'
    });
    await updateEmployee(formFields, userId);
    setProgress({
      text: 'Draft Saved!',
      severity: 'success'
    });
    setTimeout(() => {
      setProgress(null);
    }, 2000);
  };

  return (
    <Dialog fullScreen maxWidth={'xl'} open={open} >
      <DialogTitle className={classes.title}>33SIXTY FLEXFORM SF/LA<br/>WEEKLY SALES MEETING REPORT</DialogTitle>
      <If condition={progress}>
        <SnackProgress progress={progress} />
      </If>
      <Divider/>
      <DialogContent>
        <Form
          id={id}
          setFormState={setFormState}
          initialValues={newReportDialogInitialValues}
          setInitialValues={setInitialValues}
        />
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button variant="outlined" width={136} onClick={handleCancel}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          width={136}
          onClick={handleSaveDraft}
        >
          Save Draft
        </Button>
        <Button
          variant="contained"
          color="primary"
          width={136}
          onClick={() => {setPreview(true)}}
        >
          Preview and Export PDF
        </Button>
      </DialogActions>

      <Dialog fullScreen maxWidth={'md'} open={preview}>
        <DialogContent>
          <div className={classes.PDFViewer}>
            <PDFViewer style={styles.PDFViewer}>
              <PDFPreview
                notes={formState.notes}
                scheduledPresentations={formState.scheduledPresentations}
                quotesAndLeads={formState.quotesAndLeads}
                lastWeekValues={formState.lastWeekValues}
                nextWeekValues={formState.nextWeekValues}
                username={username}
                date={date}
              />
            </PDFViewer>
          </div>
        </DialogContent>
          <Divider/>
          <DialogActions className={classes.button}>
            <Button variant="outlined" width={136} onClick={handleCancel}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              width={136}
              onClick={() => {setPreview(false)}}
            >
              Back
            </Button>  
            <Button
              variant="contained"
              color="primary"
              width={136}
            >
              <DownloadLink
                notes={formState.notes}
                scheduledPresentations={formState.scheduledPresentations}
                quotesAndLeads={formState.quotesAndLeads}
                lastWeekValues={formState.lastWeekValues}
                nextWeekValues={formState.nextWeekValues}
                username={username}
                date={date}
              />
            </Button>
          </DialogActions>      
      </Dialog>
    </Dialog>
  );
};

export default NewReportDialog;